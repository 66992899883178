import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import { numberWithCommas } from 'utility/utils';
import { METRIC_EARNINGS } from "constants/types";


const styles = (theme) => ({
  root: {
    display: "-webkit-box",
    // width: 356,
    width: "100%",
    // maxWidth: "100%",
    margin: theme.spacing(1),
    marginTop: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    cursor: "pointer",
    background: theme.palette.background.main,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 2px rgba(63,63,68,0.15)`,
  },
  container: {
  },
  ranking: {
    fontSize: '20px',
    fontStyle: 'italic',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  avatar: {
    minWidth: 72,
  },
  image: {
    objectFit: "cover",
    height: 72,
    width: 72,
    borderRadius: 5
  },
  text: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.primary
  },
  name: {
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.text.primary
  },
  description: {
    fontSize: "12px",
    lineHeight: "14px",
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  title: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.primary
  },
  album_image: {
    objectFit: "cover",
    marginLeft: theme.spacing(3),
    height: 40,
    width: 40,
  }
});

class RankingCard extends React.Component {

  render() {
    const { classes, artist, ranking, onClickArtist } = this.props;

    const topsong = artist.topsongTrack !== null ? artist.topsongTrack.name : null;
    const topsongCover = artist.topsongTrack !== null ? artist.topsongTrack.coverArt : null;
    let latestrelease = null;
    let latestCover = null;
    if (artist.latest !== null && artist.latest.name !== undefined) {
      latestrelease = `${artist.latest.name} - ${artist.latest.date.year}`;
      if (artist.latest.coverArt !== undefined) {
        latestCover = artist.latest.coverArt.sources[0].url;
      }
    }

    const catalogEarnings = Math.floor(artist.monthlyListeners * 12 * METRIC_EARNINGS);

    return (
      <Card className={classes.root}>
        <CardActionArea onClick={e => onClickArtist(artist)}>
          <CardContent>
            <ListItem alignItems="flex-start"
              key={artist.id}
              ContainerComponent="div"
            >
              <ListItemAvatar className={classes.avatar}>
                <img
                  className={classes.image}
                  alt={artist.name}
                  src={artist.avatarImage ? artist.avatarImage : "/static/images/blank_avatar.png"}
                />
              </ListItemAvatar>
              <ListItemText
                id={artist.id}
                className={classes.text}
                primary={
                  <div>
                    <Typography className={classes.name}>
                      {artist.name}
                    </Typography>
                    <Typography className={classes.description}>
                      Monthly Listeners &emsp;&nbsp;{numberWithCommas(artist.monthlyListeners)}
                    </Typography>
                    <Typography className={classes.description}>
                      Followers &emsp;&emsp;&emsp;&emsp;&emsp;{numberWithCommas(artist.followers)}
                    </Typography>
                    <Typography className={classes.description}>
                      Catalog Earnings &emsp;$ {numberWithCommas(catalogEarnings)}
                    </Typography>
                  </div>
                }
              />
              <ListItemSecondaryAction>
                <Typography className={classes.ranking}>
                  {ranking}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            {topsong && topsongCover &&
              <ListItem 
                className={classes.container} 
                key={`${artist.id}-topsong`}
                ContainerComponent="div"
              >
                <ListItemAvatar className={classes.avatar}>
                  <img
                    className={classes.album_image}
                    alt={topsong}
                    src={topsongCover}
                  />
                </ListItemAvatar>
                <ListItemText
                  id={`${artist.id}-topsong`}
                  className={classes.text}
                  primary={
                    <div>
                      <Typography className={classes.title}>
                        {"Top Song"}
                      </Typography>
                      <Typography className={classes.description}>
                        {topsong}
                      </Typography>
                    </div>
                  }
                />
              </ListItem>
            }
            {latestrelease && latestCover &&
              <ListItem 
                className={classes.container} 
                key={`${artist.id}-latest`}
                ContainerComponent="div"
              >
                <ListItemAvatar className={classes.avatar}>
                  <img
                    className={classes.album_image}
                    alt={latestrelease}
                    src={latestCover}
                  />
                </ListItemAvatar>
                <ListItemText
                  id={`${artist.id}-latest`}
                  className={classes.text}
                  primary={
                    <div>
                      <Typography className={classes.title}>
                        {"Latest Release"}
                      </Typography>
                      <Typography className={classes.description}>
                        {latestrelease}
                      </Typography>
                    </div>
                  }
                />
              </ListItem>
            }
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

RankingCard.propTypes = {
  className: PropTypes.string,
};

export default withStyles(styles)(RankingCard);
