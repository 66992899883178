import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { List, ListItem } from "@material-ui/core";
import { RankingCard } from "components";
import {
  getArtistCount,
  getArtists
} from "services/artistServices";
import {
  getGenreArtistCount,
  getGenreArtists
} from "services/genreServices";
import { TAB_ARTIST } from 'constants/types';


const styles = theme => ({
  root: {
    width: "100%",
    // padding: theme.spacing(1),
    // marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.main
  },
  listitem: {
    padding: 0
  }
});

class ArtistList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage   : 25,
      page          : 0,
    };

    this.handleClickArtist = this.handleClickArtist.bind(this);
    this.handleNeedMore = this.handleNeedMore.bind(this);
    this.scrollFn = this.listenToScroll.bind(this);
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  componentDidMount = async() => {
    window.addEventListener("scroll", this.scrollFn);
    window.scrollTo(this.props.scrollPos.x, this.props.scrollPos.y);

    const { selected_genre, filter, page_no, page_size, artists_list } = this.props;
    this.setState({
      ...this.state,
      rowsPerPage: page_size,
      page: page_no
    });

    if (artists_list.length === 0) {
      await this.getArtistsOfPage(selected_genre, filter, page_no, page_size);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollFn);
  }

  getArtistsOfPage = async (genre, filter, pageNo, pageSize) => {
    this.setWaiting(true);

    // console.log("Get Artist page :", genre, pageNo, pageSize);

    let artist_count = 0;
    let artists = [];
    if (genre !== null) {
      artist_count = await getGenreArtistCount(genre.id);
      artists = await getGenreArtists(genre.id, filter, pageSize, pageNo * pageSize);
      let index = 1;
      for (let artist of artists) {
        artist.genre_index = pageNo * pageSize + index;
        index ++;
      }
    } else {
      artist_count = await getArtistCount();
      artists = await getArtists(filter, pageSize, pageNo * pageSize);
      console.log("filter :", filter, "artists :", artists, ", pageNo :", pageNo);
    }

    this.props.setArtistCount(artist_count);
    this.props.appendArtistsList(artists);

    this.setWaiting(false);
  }

  handleClickArtist = artist => {
    this.props.saveScrollPos(window.scrollX, window.scrollY);

    this.props.selectArtist(artist);
    this.props.setCurrentTab(TAB_ARTIST);
    this.props.onClickArtist(artist.slug);
  };


  listenToScroll = (event) => {
    event.preventDefault();

    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight - window.innerHeight;
    if (height === 0) {
      return;
    }

    const scrolled = winScroll * 1.0 / height;

    // console.log("listenToScroll saveScrollPos");
    this.props.saveScrollPos(window.scrollX, window.scrollY);
    if (scrolled === 0.0) {
      // this.props.saveScrollPos(window.scrollX, window.scrollY);
    } else if (scrolled >= 0.99) {
      // this.props.saveScrollPos(window.scrollX, height);
      this.props.saveScrollPos(window.scrollX, window.scrollY);
      this.handleNeedMore();
    }
  };

  handleNeedMore = () => {
    const page = this.state.page + 1;

    // get artists of this page
    const { selected_genre, page_size, filter, requesting} = this.props;
    if (requesting) {
      return;
    }

    this.getArtistsOfPage(selected_genre, filter, page, page_size);

    this.props.setPageNo(page);
    this.setState({
      ...this.state,
      page: page
    });
  }

  render() {
    const { 
      classes,
      artists_list,
      selected_genre
    } = this.props;

    // console.log("artists_list :", artists_list);

    if (artists_list.length === 0) {
      return <div></div>;
    }

    return (
      <div className={classes.root}>
        <List component="artist-list" aria-label="artist list">
          {artists_list.map(artist => (
            <ListItem className={classes.listitem} key={artist.id}>
              <RankingCard
                artist={artist}
                ranking={selected_genre === null ? artist.worldRank : artist.genre_index}
                onClickArtist={this.handleClickArtist}
              />
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  scrollPos: state.uiState.scrollPos,
  requesting: state.uiState.requesting,
  page_no: state.uiState.page_no,
  page_size: state.uiState.page_size,
  selected_genre: state.dataState.selected_genre,
  artists_list: state.dataState.artists_list,
  artist_count: state.dataState.artist_count,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ArtistList);
