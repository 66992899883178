import * as ActionTypes from "actions/ActionTypes";
import { TAB_RANKINGS, TAB_TOPEARNERS, TAB_TOPSONGS } from "constants/types";

const initialState = {
  initialized: false,
  searchKey: "",
  searchResults: [],
  genres: [],
  artists: [],
  artists_list: [],
  artist_count: 0,
  currentTab: TAB_RANKINGS,
  selected_genre: null,
  selected_artist: null
};

function dataReducer(state = initialState, action) {
  switch (action.type) {

    case ActionTypes.SET_GENRES: {
      let sorted_genres = action.genres.sort(
        (a, b) => a.created_at - b.created_at
      );

      let selected_genre = state.selected_genre;
      // if (selected_genre === null && sorted_genres.length > 0) {
      //   selected_genre = sorted_genres[0];
      // }

      return {
        ...state,
        genres: sorted_genres,
        selected_genre: selected_genre,
        searchResults: []
      };
    }

    case ActionTypes.SELECT_GENRE: {
      return {
        ...state,
        selected_genre: action.genre,
        selected_artist: null,
        searchResults: []
      };
    }

    case ActionTypes.SET_ARTIST_COUNT: {
      return {
        ...state,
        artist_count: action.count
      };
    }

    case ActionTypes.SET_CURRENT_TAB: {
      return {
        ...state,
        currentTab: action.tabValue
      };
    }

    case ActionTypes.SET_ARTISTS: {
      // const new_artists = JSON.parse(JSON.stringify(action.artists));
      return {
        ...state,
        artists: action.artists,
        searchResults: []
      }
    }

    case ActionTypes.SELECT_ARTIST: {
      return {
        ...state,
        selected_artist: action.artist
      };
    }

    case ActionTypes.SET_ARTISTS_LIST: {
      return {
        ...state,
        artists_list: action.artists,
        searchResults: []
      }
    }

    case ActionTypes.APPEND_ARTISTS_LIST: {
      let artists = state.artists_list;
      const new_artists = JSON.parse(JSON.stringify(action.artists));
      for (let artist of new_artists) {
        if (artists.findIndex(item => item.id === artist.id) === -1) {
          artists.push(artist);
        } else {
          console.log("duplicate artist : ", artist.id, artist.name);
        }
      }

      if (state.currentTab === TAB_RANKINGS) {
        artists.sort((a, b) => a.worldRank - b.worldRank);
      } else if (state.currentTab === TAB_TOPSONGS) {
        artists.sort((a, b) => b.topsongPlaycount - a.topsongPlaycount);
      } else if (state.currentTab === TAB_TOPEARNERS) {
        artists.sort((a, b) => b.monthlyListeners - a.monthlyListeners);
      } else {
        artists.sort((a, b) => a.worldRank - b.worldRank);
      }

      // console.log("sorted artists : ", artists);
      
      return {
        ...state,
        artists_list: artists,
      }
    }

    case ActionTypes.SET_SEARCHKEY: {
      return {
        ...state,
        searchKey: action.searchkey
      };
    }

    case ActionTypes.SET_SEARCHRESULT: {
      return {
        ...state,
        searchResults: action.artists
      };
    }

    default:
      return state;
  }
}

export default dataReducer;
