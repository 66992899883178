import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Collapse } from "@material-ui/core";
import { ArtistCard } from "components";
import { getTimestamps, numberWithCommas } from 'utility/utils';
import {
  formatNumberWithCommas,
  getArtistWeeklyStreamChange,
  getArtistStreamCountsByWeek,
} from "services/artistServices";
import { METRIC_EARNINGS } from "constants/types";

const styles = (theme) => ({
  root: {
    position: 'relative',
  },
  cover_container: {
    position: 'relative',
    marginBottom: 2,
    backgroundImage: "linear-gradient(#05050B 100%, #111118 30%)",
    height: 144,
  },
  cover: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 'auto',
    height: 144,
    maskImage: "linear-gradient(to right, transparent 0%, black 30%, black 80%, transparent 100%)",
    webkitMaskImage: "linear-gradient(to right, transparent 0%, black 30%, black 80%, transparent 100%)",
  },
  social_container: {
    position: 'absolute',
    top: 110,
    left: 200,
  },
  social_icon: {
    display: 'inline',
    cursor: 'pointer',
    width: 20,
    height: 20,
    marginLeft: theme.spacing(2),
  },
  avatar_container: {
    position: 'absolute',
    top: 100,
    left: 40,
  },
  avatar: {
    width: 144,
    height: 144,
  },
  name: {
    position: 'absolute',
    top: 32,
    left: 132,
    fontFamily: 'system-ui',
    fontSize: '48px',
    lineHeight: '52px',
    letterSpacing: '-2px',
    fontWeight: 700,
    color: 'white',
    textTransform: 'uppercase',
  },
  url: {
    position: 'absolute',
    top: 80,
    left: 288,
    fontFamily: 'system-ui',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.5px',
    fontWeight: 700,
    color: 'white',
    textTransform: 'uppercase',
  },
  info_container: {
    marginBottom: theme.spacing(2),
    border: '1px solid black',
    height: 112,
    width: '100%',
  },
  info_content: {
    margin: theme.spacing(3),
    marginLeft: 210,
  },
  infoItem: {
    textAlign: 'center'
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    marginBottom: theme.spacing(1),
  },
  data: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0px',
    marginTop: theme.spacing(1),
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: 600
  },
  rank_container: {
    marginBottom: theme.spacing(1),
    border: '1px solid black',
    height: 156,
    width: '100%',
  },
  rank_content: {
    margin: theme.spacing(3),
  },
  rankItem: {
    borderRight: '1px solid black',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    vericalAlign: 'middle',
  },
  rankLastItem: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    vericalAlign: 'middle',
  },
  rank: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: '#3579b8',
  },
  weekly_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingTop: 56,
    border: '1px solid black',
    height: 144,
    textAlign: 'center',
  },
  annual_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingTop: 56,
    border: '1px solid black',
    height: 144,
    textAlign: 'center',
    vericalAlign: 'middle',
  },
  bio_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    border: '1px solid black',
  },
  fan_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  fantitle: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    color: theme.palette.text.primary,
  },
  card: {
    margin: theme.spacing(1),
  },
  collapseContainer: {
    position: 'relative',
  },
  bottomBlur: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '10px', // Adjust this height as per your requirement
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
    pointerEvents: 'none',
  },
  collapseToggle: {
    cursor: 'pointer',
    color: '#3579b8',
    textAlign: 'center',
    padding: 0,
    margin: '0.5em 0 0 0',
    fontWeight: 'bold'
  }
});

class ArtistContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      weeklyStreams: "",
      weeklyChangeNumber: 0,
      weeklyChange: "",
      weeklyEarnings: ""
    }

    this.handleClickSocial = this.handleClickSocial.bind(this);
    this.handleCollapseToggle = this.handleCollapseToggle.bind(this);
  }

  componentDidMount = async () => {
    const { artist } = this.props;
    const timestamps = getTimestamps(Date.now());
    const weeklyStreamsNumber = await getArtistStreamCountsByWeek(artist.id, timestamps[1], timestamps[3]);
    const weeklyStreams = formatNumberWithCommas(weeklyStreamsNumber);
    const weeklyChangeNumber = await getArtistWeeklyStreamChange(artist.id);
    const weeklyChange = formatNumberWithCommas(Math.abs(weeklyChangeNumber));
    const weeklyEarnings = formatNumberWithCommas(Math.floor(weeklyStreamsNumber * METRIC_EARNINGS));

    this.setState({
      ...this.state,
      weeklyStreams: weeklyStreams,
      weeklyChangeNumber: weeklyChangeNumber,
      weeklyChange: weeklyChange,
      weeklyEarnings: weeklyEarnings
    });
  }

  componentDidUpdate = async (prevProps) => {
    const { artist } = this.props;
    if (prevProps.artist.id !== artist.id) {
      const timestamps = getTimestamps(Date.now());
      const weeklyStreamsNumber = await getArtistStreamCountsByWeek(artist.id, timestamps[1], timestamps[3]);
      const weeklyStreams = formatNumberWithCommas(weeklyStreamsNumber);
      const weeklyChangeNumber = await getArtistWeeklyStreamChange(artist.id);
      const weeklyChange = formatNumberWithCommas(Math.abs(weeklyChangeNumber));
      const weeklyEarnings = formatNumberWithCommas(Math.floor(weeklyStreamsNumber * METRIC_EARNINGS));
      
      this.setState({
        ...this.state,
        weeklyStreams: weeklyStreams,
        weeklyChangeNumber: weeklyChangeNumber,
        weeklyChange: weeklyChange,
        weeklyEarnings: weeklyEarnings
      });
    }
  }

  handleClickSocial = (url) => {
    window.open(url, '_blank').focus();
  }

  handleCollapseToggle = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }

  debutDate = (artist) => {
    const albums = artist.albums;
    if (albums === null || albums.length === 0) {
      return null;
    }

    let debut_ts = Date.now();
    for (let album of albums) {
      if (album !== null) {
        let album_ts = Date.parse(album.release_date)
        if (album_ts < debut_ts) {
          debut_ts = album_ts;
        }
      }

    }

    const debut_date = new Date(debut_ts);

    const options = {
      year: 'numeric', month: 'long', day: 'numeric'
    };

    var fmt = new Intl.DateTimeFormat('en-US', options);
    const debut_datestr = fmt.format(debut_date);
    // const debut_datestr = `${debut_date.getFullYear()}-${debut_date.getMonth() + 1}-${debut_date.getDate()}`;
    return debut_datestr;
  }

  render() {
    const { classes, artist, onClickArtist } = this.props;
    const {
      // ranking_followers,
      // ranking_toptrack,
      // ranking_country 
      weeklyStreams,
      weeklyChangeNumber,
      weeklyChange,
      weeklyEarnings
    } = this.state;

    let imgChange = "/static/images/icons/light/blank.png";
    let imgChangeAlt = "";
    if (weeklyChangeNumber > 0)
    {
      imgChange = "/static/images/icons/light/up.png";
      imgChangeAlt = "up";
    } else if (weeklyChangeNumber < 0) {
      imgChange = "/static/images/icons/light/down.png";
      imgChangeAlt = "down";
    }

    const externalLinks = artist.externalLinks;
    let facebook = "";
    let instagram = "";
    let twitter = "";
    // let wikipedia = "";
    if (externalLinks !== null) {
      for (let link of externalLinks) {
        if (link.name === "FACEBOOK") {
          facebook = link.url;
        } else if (link.name === "INSTAGRAM") {
          instagram = link.url;
        } else if (link.name === "TWITTER") {
          twitter = link.url;
        }
        // } else if (link.name === "WIKIPEDIA") {
        //   wikipedia = link.url;
        // }
      }
    }

    const debut_datestring = this.debutDate(artist);

    return (
      <div className={classes.root}>
        <div className={classes.cover_container}>
          {artist.headerImage &&
            <img className={classes.cover} src={artist.headerImage} alt={artist.id} />
          }
          <Typography className={classes.name}>
            {artist.name}
          </Typography>
          {/* <Typography className={classes.url}>
            {"firstcovers.com"}
          </Typography> */}
        </div>
        <div className={classes.social_container}>
          {facebook !== "" &&
            <img className={classes.social_icon}
              src={"/static/images/icons/light/facebook.png"}
              alt={"facebook"}
              onClick={e => this.handleClickSocial(facebook)}
            />
          }
          {instagram !== "" &&
            <img className={classes.social_icon}
              src={"/static/images/icons/light/instagram.png"}
              alt={"instagram"}
              onClick={e => this.handleClickSocial(instagram)}
            />
          }
          {twitter !== "" &&
            <img className={classes.social_icon}
              src={"/static/images/icons/light/twitter.png"}
              alt={"twitter"}
              onClick={e => this.handleClickSocial(twitter)}
            />
          }
        </div>
        <div className={classes.avatar_container}>
          <img
            className={classes.avatar}
            src={artist.avatarImage ? artist.avatarImage : "/static/images/blank_avatar.png"}
            alt={artist.name}
          />
        </div>

        <div className={classes.info_container}>
          <div className={classes.info_content}>
            <Grid container>
              <Grid item className={classes.infoItem} xs={3}>
                <Typography className={classes.title}>
                  Monthly Listeners
                </Typography>
                <Typography className={classes.data}>
                  {numberWithCommas(artist.monthlyListeners)}
                </Typography>
              </Grid>

              <Grid item className={classes.infoItem} xs={3}>
                <Typography className={classes.title}>
                  Global Rank
                </Typography>
                <Typography className={classes.data}>
                  {artist.worldRank}
                </Typography>
              </Grid>

              <Grid item className={classes.infoItem} xs={3}>
                <Typography className={classes.title}>
                  Followers
                </Typography>
                <Typography className={classes.data}>
                  {numberWithCommas(artist.followers)}
                </Typography>
              </Grid>

              <Grid item className={classes.infoItem} xs={3}>
                <Typography className={classes.title}>
                  Artist Debut
                </Typography>
                <Typography className={classes.data}>
                  {debut_datestring === null ? "---" : debut_datestring}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={classes.rank_container}>
          <div className={classes.rank_content}>
            <Grid container>
              <Grid item className={classes.rankItem} xs={4}>
                <Typography className={classes.rank}>
                  {artist.allTimeStreams}
                </Typography>
                <Typography className={[classes.data, classes.bold]}>
                  {"All-Time Streams"}
                </Typography>
              </Grid>

              <Grid item className={classes.rankItem} xs={4}>
                <Typography className={classes.rank}>
                  {weeklyStreams}
                </Typography>
                <Typography className={[classes.data, classes.bold]}>
                  {"Weekly Streams"}
                </Typography>
              </Grid>

              <Grid item className={classes.rankLastItem} xs={4}>
                <Typography className={classes.rank}>
                  <img className={classes.social_icon}
                    src={imgChange}
                    alt={imgChangeAlt}
                  />&nbsp;{weeklyChange}
                </Typography>
                <Typography className={[classes.data, classes.bold]}>
                  {"Weekly Change"}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid container>
          <Grid item xs={6}>
            <div className={classes.weekly_container}>
              <Typography className={classes.rank}>
                $ {weeklyEarnings}
              </Typography>
              <Typography className={[classes.data, classes.bold]}>
                {"Estimated Earnings Per Week"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.annual_container}>
              <Typography className={classes.rank}>
                $ {artist.catalogEarnings}
              </Typography>
              <Typography className={[classes.data, classes.bold]}>
                {"Estimated All-Time Catalog Earnings"}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <div className={classes.bio_container}>
          <Typography className={classes.fantitle}>
            {"Biography"}
          </Typography>
          <div className={classes.collapseContainer}>
            <Collapse in={this.state.collapsed} collapsedSize={100}>
              <div
                style={{ fontFamily: "Montserrat, sans-serif", fontWeight: 400, fontSize: "14px", lineHeight: "18px" }}
                dangerouslySetInnerHTML={{ __html: artist.biography }}
              />
            </Collapse>
            {this.state.collapsed ? "" : <div className={classes.bottomBlur}></div>}
          </div>
          {this.state.collapsed ? <p onClick={this.handleCollapseToggle} className={classes.collapseToggle}></p> : <p onClick={this.handleCollapseToggle} className={classes.collapseToggle}><small>See more</small></p>}
        </div>
        {artist.relatedArtists &&
          <div className={classes.fan_container}>
            <Typography className={classes.fantitle}>
              {"Fans also like"}
            </Typography>
            <Grid container>
              {artist.relatedArtists.map(relatedArtist =>
                <Grid item className={classes.card}>
                  <ArtistCard
                    artist={relatedArtist}
                    isMobile={false}
                    onClickArtist={onClickArtist}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        }
      </div>
    );
  }
}

ArtistContent.propTypes = {
  className: PropTypes.string,
};


export default withStyles(styles)(ArtistContent);
