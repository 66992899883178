import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Container, Input, Hidden, Dialog, DialogContent, Button, DialogTitle, DialogContentText, DialogActions } from "@material-ui/core";
import {
    WaitingDialog,
    SnackAlert
} from "components";
// import { getGenres } from "services/genreServices";
import { addToWaitlist } from "services/waitlistService";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.main,
    },
    maincontainer: {
        position: "relative",
        paddingTop: 180,
        // top: "180px",
        [theme.breakpoints.down("sm")]: {
            // top: "180px",
            paddingTop: 130,
        },
        padding: theme.spacing(2),
    },
    catalogueImage: {
        height: "460px",
        position: "absolute",
        right: "40%"
    },
    catalogueImageMobile: {
        height: "320px",
        position: "absolute",
        right: "40%"
    },
    contestImage: {
        height: "380px",
        position: "absolute",
        left: "50%",
        top: "3rem"
    },
    contestImageMobile: {
        height: "240px",
        position: "absolute",
        left: "50%",
        top: "3rem"
    },
    separator: {
        width: "80%",
        margin: "0 0 1.5rem 0",
        border: 0,
        borderTop: "1px solid #255a8e",
        height: "1px"
    },
    separatorMobile: {
        width: "80%",
        margin: "0 auto 1.5rem auto",
        border: 0,
        borderTop: "1px solid #255a8e",
        height: "1px"
    },
    title: {
        width: "50%",
        fontWeight: 900,
        marginBottom: "1.5rem"
    },
    titleMobile: {
        fontWeight: 900,
        marginBottom: "1.5rem"
    },
    enlarged: {
        fontSize: "36px",
    },
    imgContainer: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    inputText: {
        width: "100%",
        padding: "2px 10px",
        marginBottom: "1rem",
        border: "1px solid #255a8e",
        borderRadius: 5,
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-0.05px',
        color: "#255a8e",
    },
    waitlistTitle: {
        color: "#3579b8",
        fontSize: "48px",
        textAlign: "center",
        fontWeight: 900,
        margin: "1rem"
    },
    waitlistText: { textAlign: "center", color: "#3579b8", marginBottom: "2rem" },
    joinBtn: {
        backgroundColor: "#3579b8",
        width: "100%",
        color: "white",
        border: "none",
        padding: "0.5rem",
        borderRadius: "5px",
        fontSize: "16px",
        fontWeight: 600
    },
    dialogHeader: {
        backgroundColor: "#3579b8",
    }
});

class Waitlist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            open: false
        };

        this.handleCloseError = this.handleCloseError.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount = () => {
        if (this.checkOnline()) {
            this.initialize();
        } else {
            this.props.setError("Internet disconnected! Check your internet connection.");
        }
        window.scrollTo(0, 0);
    }

    setError = (message) => {
        this.props.setError(message);
        this.props.requestDataFinished();
    }

    setWaiting = (waiting) => {
        if (waiting) {
            this.props.requestDataPending();
        } else {
            this.props.requestDataFinished();
        }
    }

    checkOnline = () => {
        return window.navigator.onLine ? true : false;
    }

    initialize = async () => {
        // this.setWaiting(true);

        // // Get Genres
        // try {
        //     const genres = await getGenres();
        //     this.props.setGenres(genres);
        // } catch (err) {
        //     this.setError(err.message);
        // }

        // this.setWaiting(false);
    }

    _getAuthToken = async () => {
        const { authUser } = this.props;
        let token = authUser.token;
        return token;
    }

    handleCloseError = () => {
        this.props.clearError();
    }

    handleNameChange = event => {
        this.setState({
            ...this.state,
            name: event.target.value
        });
    };

    handleEmailChange = event => {
        this.setState({
            ...this.state,
            email: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();
        // if name or email is blank, just return
        if (!this.state.name && !this.state.email) {
            return;
        }
        this.setState({
            ...this.state,
            open: true
        })
        await addToWaitlist(this.state.name, this.state.email)

    }

    handleClose = (e) => {
        this.setState({
            open: false,
            name: "",
            email: ""
        })

    }

    render() {
        const {
            classes,
            requesting,
            error,
            errorMsg,
        } = this.props;

        return (
            <div className={classes.root}>
                <Hidden smDown>
                    <div className={classes.maincontainer}>
                        <Container maxWidth="lg">
                            <Grid container>
                                <Grid item md={8} style={{ color: "#255a8e" }}>
                                    <h2 className={classes.title}>Introducing the first <br></br><span className={classes.enlarged}>Fantasy Music Gaming Platform</span></h2>
                                    <hr className={classes.separator}></hr>
                                    <p style={{ width: "60%" }}>Compete to win cash and prizes by predicting the streaming performance of your favorite artist and bands.
                                        <br /><br />Build a team of your favorite artists. The more <em>they</em> <b>stream</b>--the higher<em>your</em>  <b>score</b>.</p>
                                </Grid>
                                <Grid item md={4} style={{ height: "460px", width: "100%" }}>
                                    <div className={classes.imgContainer}>
                                        <img className={classes.catalogueImage} src={"/static/images/build-your-catalogue-mockup.png"} alt={"iPhone display of gaming platform"} />
                                        <img className={classes.contestImage} src={"/static/images/mogul-contest-phone-mockup.png"} alt={"iPhone display of gaming platform"} />
                                    </div>

                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <h1 className={classes.waitlistTitle}>
                                        JOIN THE WAITLIST!
                                    </h1>
                                    <p className={classes.waitlistText}>
                                        Sign up today and be the first to get notified on new updates.
                                    </p>
                                    <Input
                                        disableUnderline
                                        className={classes.inputText}
                                        placeholder="Enter your Name"
                                        onChange={this.handleNameChange}
                                        required
                                        value={this.state.name}
                                    />
                                    <Input
                                        disableUnderline
                                        className={classes.inputText}
                                        placeholder="Enter your Email"
                                        onChange={this.handleEmailChange}
                                        type="email"
                                        value={this.state.email}
                                        required
                                    />
                                    <button
                                        className={classes.joinBtn}
                                        onClick={this.handleSubmit}
                                    >
                                        JOIN THE WAITLIST
                                    </button>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <div className={classes.maincontainer}>
                        <Container maxWidth="lg">
                            <div style={{ color: "#255a8e", textAlign: 'center' }}>
                                <h2 className={classes.titleMobile}>Introducing the first <br></br><span className={classes.enlarged}>Fantasy Music Gaming Platform</span></h2>
                                <hr className={classes.separatorMobile}></hr>
                                <p>Compete to win cash and prizes by predicting the streaming performance of your favorite artist and bands.
                                    <br /><br />Build a team of your favorite artists. The more <em>they</em> <b>stream</b>--the higher<em>your</em>  <b>score</b>.</p>
                            </div>
                            <div style={{ height: "320px", width: "100%" }}>
                                <div className={classes.imgContainer}>
                                    <img className={classes.catalogueImageMobile} src={"/static/images/build-your-catalogue-mockup.png"} alt={"iPhone display of gaming platform"} />
                                    <img className={classes.contestImageMobile} src={"/static/images/mogul-contest-phone-mockup.png"} alt={"iPhone display of gaming platform"} />
                                </div>
                            </div>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <h1 className={classes.waitlistTitle}>
                                        JOIN THE WAITLIST!
                                    </h1>
                                    <p className={classes.waitlistText}>
                                        Sign up today and be the first to get notified on new updates.
                                    </p>
                                    <Input
                                        disableUnderline
                                        className={classes.inputText}
                                        placeholder="Enter your Name"
                                        onChange={this.handleNameChange}
                                    />
                                    <Input
                                        disableUnderline
                                        className={classes.inputText}
                                        placeholder="Enter your Email"
                                        onChange={this.handleEmailChange}
                                    />
                                    <button
                                        className={classes.joinBtn}
                                        onClick={this.handleSubmit}
                                    >
                                        JOIN THE WAITLIST
                                    </button>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </Hidden>
                <WaitingDialog open={requesting} />
                {error && (
                    <SnackAlert
                        variant={"error"}
                        message={errorMsg}
                        openStats={error}
                        vertical={"top"}
                        horizontal={"center"}
                        handleEvent={this.handleCloseError}
                    />
                )}
                <Dialog open={this.state.open}>
                    <DialogTitle id='alert-dialog-title' className={classes.dialogHeader}>
                        <span style={{color: 'white'}}>Thank you!</span>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You have been added to the waitlist.
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={this.handleClose}>OK</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    requesting: state.uiState.requesting,
    error: state.uiState.error,
    errorMsg: state.uiState.errorMsg
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Waitlist);