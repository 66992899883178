import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Collapse } from "@material-ui/core";
import { ArtistCard } from "components";
import { getTimestamps, numberWithCommas } from 'utility/utils';
import {
  getArtistStreamCountsByWeek,
  formatNumberWithCommas,
  getArtistWeeklyStreamChange
} from "services/artistServices";
import { METRIC_EARNINGS } from "constants/types";

const styles = (theme) => ({
  root: {
    position: 'relative',
  },
  avatar_container: {
    padding: "64px 16px 0px",
    // background: "linear-gradient(rgb(232, 232, 240) 0%, transparent 100%)",
    textAlign: "center",
  },
  avatar: {
    objectFit: "cover",
    height: "192px",
    width: "192px",
    overflow: "hidden",
    marginBottom: theme.spacing(3),
    borderRadius: "50%",
    filter: "drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 1px) drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 10px)",
  },
  social_container: {
    marginBottom: theme.spacing(2),
  },
  social_icon: {
    display: 'inline',
    cursor: 'pointer',
    width: 20,
    height: 20,
    marginLeft: theme.spacing(2),
  },
  name: {
    fontFamily: 'Montserrat',
    fontSize: '32px',
    lineHeight: '36px',
    letterSpacing: '-2px',
    fontWeight: 700,
    color: 'black',
    textTransform: 'uppercase',
  },
  url: {
    position: 'absolute',
    top: 80,
    left: 288,
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.5px',
    fontWeight: 700,
    color: 'white',
    textTransform: 'uppercase',
  },
  info_container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 158,
    display: 'inline',
  },
  data: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '18px',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 138,
    display: 'inline',
  },
  rank_container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  rank: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
    color: '#3579b8',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 138,
    display: 'inline',
  },
  rank_icon: {
    display: 'inline',
    width: 16,
    height: 16,
    marginLeft: 0,
  },
  earning_container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  bio_container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    // border: '1px solid black',
  },
  fan_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  fantitle: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    color: theme.palette.text.primary,
  },
  card: {
    margin: theme.spacing(1),
  },
  collapseContainer: {
    position: 'relative',
  },
  bottomBlur: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '10px', // Adjust this height as per your requirement
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
    pointerEvents: 'none',
  },
  collapseToggle: {
    cursor: 'pointer',
    color: '#3579b8',
    textAlign: 'center',
    padding: 0,
    margin: '0.5em 0 0 0',
    fontWeight: 'bold'
  }
});

class ArtistContentMobile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ranking_followers: 0,
      ranking_toptrack: 0
      // ranking_country: 0
    }

    this.handleClickSocial = this.handleClickSocial.bind(this);
    this.handleCollapseToggle = this.handleCollapseToggle.bind(this);

  }

  componentDidMount = async () => {
    const { artist } = this.props;
    const timestamps = getTimestamps(Date.now());
    const weeklyStreamsNumber = await getArtistStreamCountsByWeek(artist.id, timestamps[1], timestamps[3]);
    const weeklyStreams = formatNumberWithCommas(weeklyStreamsNumber);
    const weeklyChangeNumber = await getArtistWeeklyStreamChange(artist.id);
    const weeklyChange = formatNumberWithCommas(Math.abs(weeklyChangeNumber));
    const weeklyEarnings = formatNumberWithCommas(Math.floor(weeklyStreamsNumber * METRIC_EARNINGS));
    
    this.setState({
      ...this.state,
      weeklyStreams: weeklyStreams,
      weeklyChangeNumber: weeklyChangeNumber,
      weeklyChange: weeklyChange,
      weeklyEarnings: weeklyEarnings
    });
  }

  componentDidUpdate = async (prevProps) => {
    const { artist } = this.props;
    if (prevProps.artist.id !== artist.id) {
      const timestamps = getTimestamps(Date.now());
      const weeklyStreamsNumber = await getArtistStreamCountsByWeek(artist.id, timestamps[1], timestamps[3]);
      const weeklyStreams = formatNumberWithCommas(weeklyStreamsNumber);
      const weeklyChangeNumber = await getArtistWeeklyStreamChange(artist.id);
      const weeklyChange = formatNumberWithCommas(Math.abs(weeklyChangeNumber));
      const weeklyEarnings = formatNumberWithCommas(Math.floor(weeklyStreamsNumber * METRIC_EARNINGS));
      
      this.setState({
        ...this.state,
        weeklyStreams: weeklyStreams,
        weeklyChangeNumber: weeklyChangeNumber,
        weeklyChange: weeklyChange,
        weeklyEarnings: weeklyEarnings
      });
    }
  }

  handleClickSocial = (url) => {
    window.open(url, '_blank').focus();
  }

  handleCollapseToggle = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }

  debutDate = (artist) => {
    const albums = artist.albums;
    if (albums === null || albums.length === 0) {
      return null;
    }

    let debut_ts = Date.now();
    for (let album of albums) {
      if (album !== null) {
        let album_ts = Date.parse(album.release_date)
        if (album_ts < debut_ts) {
          debut_ts = album_ts;
        }
      }

    }

    const debut_date = new Date(debut_ts);

    const options = {
      year: 'numeric', month: 'long', day: 'numeric'
    };

    var fmt = new Intl.DateTimeFormat('en-US', options);
    const debut_datestr = fmt.format(debut_date);
    // const debut_datestr = `${debut_date.getFullYear()}-${debut_date.getMonth() + 1}-${debut_date.getDate()}`;
    return debut_datestr;
  }

  render() {
    const { classes, artist, onClickArtist } = this.props;
    const {
      // ranking_followers,
      // ranking_toptrack,
      // ranking_country,
      weeklyStreams,
      weeklyChangeNumber,
      weeklyChange,
      weeklyEarnings
    } = this.state;

    let imgChange = "/static/images/icons/light/blank.png";
    let imgChangeAlt = "";
    if (weeklyChangeNumber > 0)
    {
      imgChange = "/static/images/icons/light/up.png";
      imgChangeAlt = "up";
    } else if (weeklyChangeNumber < 0) {
      imgChange = "/static/images/icons/light/down.png";
      imgChangeAlt = "down";
    }

    const externalLinks = artist.externalLinks;
    let facebook = "";
    let instagram = "";
    let twitter = "";
    // let wikipedia = "";
    if (externalLinks !== null) {
      for (let link of externalLinks) {
        if (link.name === "FACEBOOK") {
          facebook = link.url;
        } else if (link.name === "INSTAGRAM") {
          instagram = link.url;
        } else if (link.name === "TWITTER") {
          twitter = link.url;
        }
        // } else if (link.name === "WIKIPEDIA") {
        //   wikipedia = link.url;
        // }
      }
    }

    const debut_datestring = this.debutDate(artist);

    return (
      <div className={classes.root}>
        <div className={classes.avatar_container}>
          <img className={classes.avatar}
            src={artist.avatarImage ? artist.avatarImage : "/static/images/blank_avatar.png"}
            alt={artist.id}
          />
          <div className={classes.social_container}>
            {facebook !== "" &&
              <img className={classes.social_icon}
                src={"/static/images/icons/light/facebook.png"}
                alt={"facebook"}
                onClick={e => this.handleClickSocial(facebook)}
              />
            }
            {instagram !== "" &&
              <img className={classes.social_icon}
                src={"/static/images/icons/light/instagram.png"}
                alt={"instagram"}
                onClick={e => this.handleClickSocial(instagram)}
              />
            }
            {twitter !== "" &&
              <img className={classes.social_icon}
                src={"/static/images/icons/light/twitter.png"}
                alt={"twitter"}
                onClick={e => this.handleClickSocial(twitter)}
              />
            }
          </div>
          <Typography className={classes.name}>
            {artist.name}
          </Typography>
        </div>

        <div className={classes.info_container}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.title}>
              Monthly Listeners
            </Typography>
            <Typography className={classes.data}>
              {numberWithCommas(artist.monthlyListeners)}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.title}>
              Global Rank
            </Typography>
            <Typography className={classes.data}>
              {artist.worldRank}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.title}>
              Followers
            </Typography>
            <Typography className={classes.data}>
              {numberWithCommas(artist.followers)}
            </Typography>
          </Grid>

          {/* <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.title}>
              Country
            </Typography>
            <Typography className={classes.data}>
              {artist.country || "---"}
            </Typography>
          </Grid> */}

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.title}>
              Artist Debut
            </Typography>
            <Typography className={classes.data}>
              {debut_datestring === null ? "---" : debut_datestring}
            </Typography>
          </Grid>
        </div>

        <div className={classes.rank_container}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.title}>
              {"ALL-TIME STREAMS"}
            </Typography>
            <Typography className={classes.rank}>
              {artist.allTimeStreams}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.title}>
              {"WEEKLY STREAMS"}
            </Typography>
            <Typography className={classes.rank}>
              {weeklyStreams}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.title}>
              {"WEEKLY CHANGE"}
            </Typography>
            <Typography className={classes.rank}>
              <img className={classes.rank_icon}
                src={imgChange}
                alt={imgChangeAlt}
              />&nbsp;{weeklyChange}
            </Typography>
          </Grid>

          {/* <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.title}>
              {"Country Rank"}
            </Typography>
            <Typography className={classes.rank}>
              {rankString(ranking_country)}
            </Typography>
          </Grid> */}
        </div>

        <div className={classes.rank_container}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.title}>
              {"ESTIMATED EARNINGS PER WEEK"}
            </Typography>
            <Typography className={classes.rank}>
              $ {weeklyEarnings}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.title}>
              {"ESTIMATED ALL-TIME CATALOG EARNINGS"}
            </Typography>
            <Typography className={classes.rank}>
              $ {artist.catalogEarnings}
            </Typography>
          </Grid>
        </div>


        <div className={classes.bio_container}>
          <Typography className={classes.fantitle}>
            {"Biography"}
          </Typography>
          <div className={classes.collapseContainer}>
            <Collapse in={this.state.collapsed} collapsedSize={100}>
              <div
                style={{ fontFamily: "Montserrat, sans-serif", fontWeight: 400, fontSize: "14px", lineHeight: "18px" }}
                dangerouslySetInnerHTML={{ __html: artist.biography }}
              />
            </Collapse>
            {this.state.collapsed ? "" : <div className={classes.bottomBlur}></div>}
          </div>
          {this.state.collapsed ? <p onClick={this.handleCollapseToggle} className={classes.collapseToggle}></p> : <p onClick={this.handleCollapseToggle} className={classes.collapseToggle}><small>See more</small></p>}
        </div>
        {artist.relatedArtists &&
          <div className={classes.fan_container}>
            <Typography className={classes.fantitle}>
              {"Fans also like"}
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              {artist.relatedArtists.map(relatedArtist =>
                <Grid item className={classes.card}>
                  <ArtistCard
                    artist={relatedArtist}
                    isMobile={true}
                    onClickArtist={onClickArtist}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        }
      </div>
    );
  }
}

ArtistContentMobile.propTypes = {
  className: PropTypes.string,
};


export default withStyles(styles)(ArtistContentMobile);
