import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Hidden } from '@material-ui/core';
import { Topbar, Footer } from './components';
import { MAX_WINDOW_WIDTH } from 'constants/types';

const useStyles = makeStyles(theme => ({
  
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.main,
    borderLeft: '1px solid #C0C0C0',
    borderRight: '1px solid #C0C0C0',
  },
  content: {
    backgroundColor: theme.palette.background.default
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar />

      <div className={classes.content}>
        {children}
      </div>
      
      <Hidden smDown>
        <Footer />
      </Hidden>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
